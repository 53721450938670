import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { PrismicRichText } from '@prismicio/react';
import { format } from 'date-fns';
import { htmlSerializer } from '../../utils/htmlSerializer';

export const NewsRoll = ({ posts }) => {
  return (
    <div>
      {posts.map((post, index) => {
        const headerImageFluid = post.data.header_image.gatsbyImageData;
        const lastPublicationDate = post.last_publication_date;

        const slug = post.uid;

        return (
          <div key={index} className='my-2 flex items-center py-4 md:mx-4'>
            <div className='md:h-32 md:w-48'>
              <GatsbyImage
                className='h-12 w-12 rounded-full p-8 md:h-full md:w-full md:rounded'
                image={headerImageFluid}
              />
            </div>
            <div className='ml-4 flex w-full flex-col'>
              <Link
                to={slug}
                className='text-lg font-medium leading-normal text-gray-800 hover:text-denimblue md:text-2xl'
              >
                <PrismicRichText
                  field={post.data.title.richText}
                  components={htmlSerializer}
                />
              </Link>
              <div>
                <span className='text-xs text-gray-500 md:text-sm'>
                  Posted on:{' '}
                </span>
                <span className='text-sm text-gray-700 md:text-base'>
                  {format(new Date(lastPublicationDate), 'MMMM dd, yyyy')}
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default NewsRoll;
