import React from 'react';
import { graphql } from 'gatsby';

import { NewsRoll } from '../components/news/newsRoll';
import FullWidthContainer from '../components/containers/fullWidthContainer';
import { PrismicRichText } from '@prismicio/react';
import CompactLayout from '../components/compactLayout';
import { htmlSerializer } from '../utils/htmlSerializer';
import { RichText } from 'prismic-reactjs';

export const NewsPage = ({ data }) => {
  const { title, hero_description } = data.prismicNewsrollpage.data;

  const allNewsposts = data.allPrismicNewspost;
  const posts = allNewsposts.edges.map((e) => {
    return { ...e.node };
  });

  posts.sort((p1, p2) => {
    const p1Date = new Date(p1.last_publication_date);
    const p2Date = new Date(p2.last_publication_date);

    if (p1Date.getTime() < p2Date.getTime()) {
      return 1;
    }
    if (p1Date.getTime() > p2Date.getTime()) {
      return -1;
    }
    return 0;
  });

  return (
    <CompactLayout pageTitle={RichText.asText(title.richText)}>
      <FullWidthContainer className='bg-denimblue pt-4 md:mt-4 mega:mt-8'>
        <div className='py-4 text-white'>
          <h1 className='text-2xl'>{RichText.asText(title.richText)}</h1>
          <PrismicRichText
            field={hero_description.richText}
            components={htmlSerializer}
          />
        </div>
      </FullWidthContainer>
      <div className='xs:max-w-xs my-8 sm:max-w-sm md:mx-4 md:max-w-4xl'>
        <NewsRoll posts={posts} />
      </div>
    </CompactLayout>
  );
};

export default NewsPage;

export const pageQuery = graphql`
  query NewsQuery {
    prismicNewsrollpage {
      data {
        hero_description {
          richText
        }
        title {
          richText
        }
      }
    }
    allPrismicNewspost {
      edges {
        node {
          last_publication_date
          uid
          data {
            title {
              richText
            }
            content {
              richText
            }
            header_image {
              alt
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
